import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { useLocation } from '@reach/router'

import { LinkDecorated } from 'components/Typography'

import useHeader from 'hooks/useHeader'
import usePrevious from 'hooks/usePrevious'
import {isMobile} from 'react-device-detect';

const Menu = styled.div`
  position: static;  
  display: none;

  ${p => p.theme.media.minWidth('desktop')} {
    display: block;
  }
`

const MenuItems = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;  
`

const MenuItem = styled.li`
  display: inline-block;  
  margin: 0 20px;

  pointer-events: ${p => p.forceClose ? 'none': 'all'};
  

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  > a {
    position: relative;
    z-index: 2;
  }

  a {
    font-size: 14px;   
  }
`

const Dropdown = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  background: ${p => p.theme.colors.green};
  transform: translateY(-15px);
  transition: 
    opacity ${p => p.animate ? '200' : '0'}ms ease-out, 
    visibility ${p => p.animate ? '200' : '0'}ms ease-out, 
    transform ${p => p.animate ? '300' : '0'}ms cubic-bezier(.12,.41,.2,.95);

  ${MenuItem}:hover > & {
    opacity: 1; 
    visibility: visible;
    transform: translateY(0);
  }
  &.close {
    opacity: 0!important;
    visibility: hidden!important;
    transform: translateY(-15px)!important;  
  }
`

const Linklists = styled.div`
  position: relative;  
  display: flex;
  flex: 1 0 auto;
  padding: ${p => p.theme.header.tabletHeight} 30px 30px 30px;

`

const Linklist = styled.div`
  position: relative;
  margin: 30px 75px 0 0;

  ${p => p.theme.media.minWidth('large')} {
    margin: 30px 100px 0 0;
  }

  &:last-child {
    margin-right: 0;
  }
`

const LinklistTitle = styled.div`
  position: relative;
  margin: 0 0 30px 0;
`

const LinklistItems = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  column-count: ${p => p.columns};
  column-gap: 30px;

  ${p => p.theme.media.minWidth('large')} {
    column-gap: 75px;
  }

  ${p => p.theme.media.minWidth('xlarge')} {
    column-gap: 100px;
  }
`

const LinklistItem = styled.li`
  position: relative;
  margin: 0 0 8px 0;  
`

const Features = styled.div`
  position: relative;
  display: flex;
  flex: 1 0 auto;
  padding: 0;
  max-width: 50%;
`

const Feature = styled.div`
  position: relative;  
  flex: 1;

  .gatsby-image-wrapper [data-main-image] {
    transition: none;
    opacity: 1;
  }
  
  .gatsby-image-wrapper {
    display: block;
    pointer-events: none;
    height: 100%;
  }
`

const FeatureTitle = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 2;
  text-decoration: underline;

  ${Feature}:hover & {
    text-decoration: none;
  }
`

const DesktopMenu = () => {
  const navigation = useHeader()
  const [mouseEntered, setMouseEntered] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [forceClose, setForceClose] = useState(false)
  const [forceMenuClose, setForceMenuClose] = useState(false)
  const location = useLocation()
  const prevLocation = usePrevious(location)

  useEffect(() => {    
    if ( location !== prevLocation ) {
      setForceClose(true)
      
      setTimeout(() => {
        setForceClose(false)
      }, 500)
    }
    
  }, [location, prevLocation, setForceClose])
  
  let timeout
  
  const onMenuItemMouseEnter = ( e ) => {
    if ( timeout ) clearTimeout(timeout)
    
    setMouseEntered(true)
    
    timeout = setTimeout(() => {
      setDropdownOpen(true)
    }, 200)        
  }

  const onMenuItemMouseLeave = () => {
    setMouseEntered(false)
    
    timeout = setTimeout(() => {
      setDropdownOpen(false) 
      
    }, 200)
  }

  const onMenuItemClick = () => {
    setForceMenuClose(true)
  }

  const onMenuMainItemClick = () => {
    setForceMenuClose(false)
  }  
  
  const renderLinklistItem = ( item ) => {
    return (
      <LinklistItem key={item.id}>
        <LinkDecorated to={item.link} onClick={onMenuItemClick}>
          {item.title}
        </LinkDecorated>
      </LinklistItem>
    )
  }
  
  const renderLinklists = ( linklists ) => {
    return (
      <Linklists className='js-linklist'>
        {linklists.map(linklist => {
           return (
             <Linklist key={linklist.id}>
               <LinklistTitle onClick={onMenuItemClick}>
                 <LinkDecorated to={linklist.link}>{linklist.title}</LinkDecorated>
               </LinklistTitle>
               <LinklistItems columns={linklist.links.length >= 10 ? 2 : 1}>
                 {linklist.links.map(renderLinklistItem)}
               </LinklistItems>
             </Linklist>
           )
         })}
      </Linklists>
    )
  }

  const renderFeatures = ( features ) => {
    return (
      <Features>
        {features.map(feature => {
           const image = getImage(feature.image)
           
           return (
             <Feature key={feature.id}>
               <Link to={feature.link}>
                 {!isMobile && <GatsbyImage image={image} alt={feature.image.title} /> }
                 <FeatureTitle>{feature.title}</FeatureTitle>
               </Link>
             </Feature>
           )
        })}
      </Features>
    )
  }

  const renderDropdown = ( item ) => {
    if ( !item.linkLists && !item.features ) return
    
    return (
      <Dropdown
          animate={(mouseEntered && !dropdownOpen) || (!mouseEntered && dropdownOpen)}
          className={forceMenuClose ? 'close' : ''}
          >
        {item.linkLists && renderLinklists(item.linkLists)}
        {item.features && renderFeatures(item.features)}
      </Dropdown>
    )
  }  
  
  return (    
    <Menu>
      <MenuItems>
        {navigation.navigationItems.map(item => {
           return (
             <MenuItem                 
                 forceClose={forceClose}
                 key={item.id}                 
                 onMouseEnter={(item.linkLists || item.features) && onMenuItemMouseEnter}
                 onMouseLeave={(item.linkLists || item.features) && onMenuItemMouseLeave}>
               <LinkDecorated to={item.link} trigger={MenuItem} onClick={onMenuMainItemClick}>{item.title}</LinkDecorated>
               {renderDropdown(item)}
             </MenuItem>         
           )        
        })}
      </MenuItems>
    </Menu>
  )
}

export default DesktopMenu

