import { useStaticQuery, graphql } from 'gatsby'

const useHeader = () => {
  const { allContentfulHeader } = useStaticQuery(
    graphql`
      query Header {
        allContentfulHeader(
          limit: 1, 
          filter: { title: { ne: "TEMPLATE" }}
        ) {
          nodes {
            title
            navigationItems {
              id
              link
              title
              linkLists {
                id
                title
                link
                hideLinksOnMobile
                links {
                  id
                  title
                  link
                }
              }
              features {
                id
                title
                image {
                  title
                  gatsbyImageData(width: 720)
                }
                link
              }
            }
          }          
        }
      }
    `
  )

  return allContentfulHeader.nodes[0]
}

export default useHeader
